<script setup>
</script>

<template>
    <section class="bg-black">
        <div class="container px-5 py-16 lg:py-28 md:px-7 lg:px-14 h-full">
            <div class="flex items-end h-full">
                <div>
                    <h2 class="text-white lg:w-11/12">
                        <SplitAnimate
                            text="We work closely with your business to understand its goals and objectives and use our
                                expertise to craft a unique strategy that aligns with"
                            text2="your vision."
                            text2Class="text-jade"
                        />
                    </h2>
                    <div class="description lg:w-8/12 text-white60 mt-6">
                        Our team of experts brings a wealth of experience and
                        knowledge to the table, allowing us to craft a strategy
                        that is tailored specifically to your business.
                    </div>
                    <div class="mt-10 hidden md:block">
                        <Button url="/about" severity="secondary" border>
                            Who we are
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
